@font-face {
  font-family: SofiaProRegular;
  src: url("/assets/fonts/Sofia Pro Regular.ttf") format('truetype');
}

@font-face {
  font-family: SofiaProRegularItalic;
  src: url("/assets/fonts/Sofia Pro Regular Italic.ttf") format('truetype');
}

@font-face {
  font-family: SofiaProBold;
  src: url("/assets/fonts/Sofia Pro Bold.ttf") format('truetype');
}

@font-face {
  font-family: SofiaProBoldItalic;
  src: url("/assets/fonts/Sofia Pro Bold Italic.ttf") format('truetype');
}

@font-face {
  font-family: SofiaProSemiBold;
  src: url("/assets/fonts/Sofia Pro Semi Bold.ttf") format('truetype');
}

@font-face {
  font-family: SofiaProSemiBoldItalic;
  src: url("/assets/fonts/Sofia Pro Semi Bold Italic.ttf") format('truetype');
}
