$regular: "SofiaProRegular";
$regular-italic: "SofiaProRegularItalic";
$bold: "SofiaProBold";
$bold-italic: "SofiaProBoldItalic";
$semi-bold: "SofiaProSemiBold";
$semi-bold-italic: "SofiaProSemiBoldItalic";

$main-font-family: "SofiaProRegular";
$main-font-size: 16px;
$header-big-size: 30px;
$header-large-size: 24px;
$header-medium-size: 22px;
$header-small-size: 20px;

