$white-color: #ffffff;
$black-color: #202020;
$aqua-color: #00D2E7;
$dark-blue-color: #163fa7;
$aqua2-color: #A7D7DD;
$grey-color: #A6B2B5;
$light-grey-color: #F4F9FA;
$light-aqua-color: #CEF0F4;
$light-aqua2-color: #E4F3F7;
$red-color: #FF0022;
$green-color: #6BBD6E;
$yellow-color: #FFAA2C;
$super-light-aqua-color: #F9FCFC;
$opacity-aqua-color: #F4F9FA;

$background-color: $white-color;
