@import "src/assets/css/_fonts";
@import "src/assets/css/fonts";
@import "colors";
@import '~@angular/cdk/overlay-prebuilt.css';

*, *:before, *:after {
  padding: 0;
  margin: 0;
  border: 0;
}

html, body {
  height: 100%;
}

body {
  font-family: $main-font-family;
  font-size: $main-font-size;
  background-color: $background-color;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dialog-backdrop {
    background: $black-color;
    opacity: 0.7 !important;
  }
}


//@font-face {
//  font-family: SofiaProRegular;
//  src: url("src/assets/fonts/Sofia Pro Regular.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: SofiaProRegularItalic;
//  src: url("src/assets/fonts/Sofia Pro Regular Italic.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: SofiaProBold;
//  src: url("src/assets/fonts/Sofia Pro Bold.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: SofiaProBoldItalic;
//  src: url("src/assets/fonts/Sofia Pro Bold Italic.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: SofiaProSemiBold;
//  src: url("src/assets/fonts/Sofia Pro Semi Bold.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: SofiaProSemiBoldItalic;
//  src: url("src/assets/fonts/Sofia Pro Semi Bold Italic.ttf") format('truetype');
//}
